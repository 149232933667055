import React, { useEffect, useState } from 'react';
import { gsap } from 'gsap';
import lumLogoTextWhite from 'assets/images/lum_logo_text_white.png';
import poweredByLum from 'assets/images/power_by_the_lum.png';
import lumNetworkWhite from 'assets/images/lum_network_white_logo.svg';
import copyIcon from 'assets/images/copy.svg';
import checkmarkIcon from 'assets/images/checkmark.svg';

import { Button, Footer } from 'components';
import { FIREBASE, LUM_MEDIUM_ARTICLE } from 'constant';

import './App.scss';
import ClipboardJS from 'clipboard';
import { initializeApp, FirebaseApp } from 'firebase/app';
import { getDatabase, ref, get, child } from 'firebase/database';
import { LumUtils } from '@lum-network/sdk-javascript';
import { convertUnitNumber } from '../utils';

interface Drop {
    atom: number;
    osmo: number;
}

const App = (): JSX.Element => {
    const [address, setAddress] = useState('');
    const [lumAddress, setLumAddress] = useState('');
    const [error, setError] = useState<string | null>(null);
    const [drop, setDrop] = useState<Drop | null>(null);
    const [fb, setFb] = useState<FirebaseApp | null>(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const scrollTrigger = {
            trigger: `#lum .powered-by`,
            start: 'top 65%',
            end: 'top 30%',
            scrub: true,
        };
        gsap.from(`#lum .powered-by .section-content-title`, {
            y: 40,
            opacity: 0,
            ease: 'none',
            scrollTrigger: scrollTrigger,
            stagger: 0.2,
        });
        gsap.from(`#lum .powered-by .section-content-info`, {
            y: 80,
            opacity: 0,
            ease: 'none',
            scrollTrigger: scrollTrigger,
        });
        gsap.from(`#lum .powered-by .section-content-illu`, {
            y: 120,
            scale: 0.75,
            opacity: 0,
            ease: 'none',
            scrollTrigger: scrollTrigger,
        });

        const clipboard = new ClipboardJS('#copy-btn');
        clipboard.on('success', (e) => {
            e.clearSelection();
        });

        return () => {
            clipboard.destroy();
        };
    }, []);

    useEffect(() => {
        setFb(initializeApp(FIREBASE));
    }, []);

    const onCheck = () => {
        if (!address) {
            setError('Address required');
        } else if (!address.startsWith('cosmos') && !address.startsWith('osmo')) {
            setError('Wrong address');
        } else {
            if (!fb) {
                setError('Firebase error');
                return;
            }

            setLoading(true);

            let encodeAddress = '';

            try {
                const decode = LumUtils.Bech32.decode(address);
                encodeAddress = LumUtils.Bech32.encode('lum', decode.data);
            } catch (e) {
                setError('Wrong address');
                setLoading(false);
                return;
            }

            setLumAddress(encodeAddress);

            const database = getDatabase(fb);
            const dbRef = ref(database);
            get(child(dbRef, `airdrop/${encodeAddress}`))
                .then((snapshot) => {
                    if (snapshot.exists()) {
                        const finalDrop = {
                            atom: convertUnitNumber(snapshot.val().atom),
                            osmo: convertUnitNumber(snapshot.val().osmo),
                        };

                        setDrop(finalDrop);
                    } else {
                        setDrop({
                            atom: 0,
                            osmo: 0,
                        });
                    }
                })
                .catch(() => {
                    setError('Firebase error');
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    };

    const resetCheck = () => {
        setError(null);
        setAddress('');
        setDrop(null);
    };

    const isEligible = drop && (drop.atom > 0 || drop.osmo > 0);

    return (
        <div className="dark min-vh-100 d-flex flex-column justify-content-between" id="lum">
            <div className="d-flex justify-content-center align-items-center">
                <img className="lum-network-logo" src={lumNetworkWhite} alt="Lum network" />
            </div>
            <div id="lum-content" className="container">
                <div className="row powered-by d-flex justify-content-center">
                    <div
                        className="col-12 d-flex align-items-center justify-content-center flex-wrap"
                        style={{ position: 'relative' }}
                    >
                        {(!drop || !isEligible) && (
                            <h1 className="section-content-title part-1 text-center">It's time</h1>
                        )}
                        <h1
                            className={`section-content-title part-2 text-center d-flex justify-content-center flex-wrap ${
                                isEligible && 'gotham mt-5'
                            }`}
                        >
                            {isEligible ? (drop.atom + drop.osmo).toFixed() : 'to create'}
                            <span className="ms-3 ms-sm-0">
                                <img
                                    className={`section-content-title text-center part-3`}
                                    src={lumLogoTextWhite}
                                    alt="Lum"
                                />
                            </span>
                        </h1>

                        <img className="section-content-illu" src={poweredByLum} alt="Huge Lum Coin" />
                    </div>
                </div>
            </div>
            <div className="d-flex flex-column flex-grow-1 justify-content-center align-items-center justify-self-end mb-4">
                {drop ? (
                    <>
                        {!isEligible ? (
                            <>
                                <h3>Not eligible 😥</h3>
                                <p className="mt-2 text-center">
                                    Read about the airdrop criteria in our{' '}
                                    <a
                                        href={LUM_MEDIUM_ARTICLE}
                                        rel="noreferrer"
                                        target="_blank"
                                        className="text-link text-white"
                                    >
                                        <b>announcement post.</b>
                                    </a>
                                </p>
                            </>
                        ) : (
                            <>
                                <div className="d-flex justify-content-center address-container mt-auto mb-5 w-100">
                                    <h5 className="address fw-light text-truncate">{lumAddress}</h5>
                                    <span className="ms-2">
                                        <button
                                            type="button"
                                            className="p-0 ms-2"
                                            id="copy-btn"
                                            data-clipboard-text={lumAddress}
                                        >
                                            <img src={copyIcon} alt="Copy" />
                                        </button>
                                    </span>
                                </div>
                                <div className="d-flex flex-row flex-wrap align-items-center justify-content-center mb-3">
                                    {drop.atom > 0 && (
                                        <div
                                            className={`d-flex flex-row align-items-center ${
                                                drop.osmo ? 'me-3 me-md-5' : ''
                                            }`}
                                        >
                                            <img src={checkmarkIcon} alt="checkmark" className="mb-2 me-3" />
                                            <h3>ATOM Staker</h3>
                                            {drop.osmo > 0 && (
                                                <span className="gotham fs-5">
                                                    : {drop.atom.toFixed()}{' '}
                                                    <img
                                                        className="lum-ticker align-baseline"
                                                        src={lumLogoTextWhite}
                                                        alt="Lum"
                                                    />
                                                </span>
                                            )}
                                        </div>
                                    )}
                                    {drop.osmo > 0 && (
                                        <div className="d-flex flex-row align-items-center">
                                            <img src={checkmarkIcon} alt="checkmark" className="mb-2 me-3" />
                                            <h3>OSMOSIS LP</h3>
                                            {drop.atom > 0 && (
                                                <span className="gotham fs-5">
                                                    : {drop.osmo.toFixed()}{' '}
                                                    <img
                                                        className="lum-ticker align-baseline"
                                                        src={lumLogoTextWhite}
                                                        alt="Lum"
                                                    />
                                                </span>
                                            )}
                                        </div>
                                    )}
                                </div>
                            </>
                        )}
                    </>
                ) : (
                    <div className="input-container text-center mb-4">
                        <input
                            className="w-100"
                            placeholder="Address (Cosmos / Osmosis)"
                            value={address}
                            onChange={(event) => {
                                if (error) {
                                    setError('');
                                }
                                setAddress(event.currentTarget.value);
                            }}
                        />
                        <p className={`error mt-2 text-start display-${error ? 'block' : 'none'}`}>{error || ''}</p>
                    </div>
                )}
                <Button
                    loading={loading}
                    className="rounded-pill mt-auto mb-4 mb-md-0"
                    onClick={drop ? resetCheck : onCheck}
                >
                    <b>{drop ? 'Check Another Address' : 'Check Airdrop'}</b>
                </Button>
            </div>
            <Footer />
        </div>
    );
};

export default App;
