export const LUM_GITHUB = 'https://github.com/lum-network';
export const LUM_TWITTER = 'https://twitter.com/lum_network';
export const LUM_DISCORD = 'https://discord.gg/KwyVvnBcXF';
export const LUM_MEDIUM = 'https://medium.com/lum-network';
export const LUM_MEDIUM_ARTICLE = 'https://medium.com/lum-network/lum-airdrop-for-atom-stakers-osmo-lps-120d3e472f38';
export const LUM_TELEGRAM = 'https://t.me/lum_network';
export const FIREBASE = {
    apiKey: 'AIzaSyA49DTslUcJG2b5TUdI_EbPzugFFXOfO0o',
    authDomain: 'lum-network.firebaseapp.com',
    databaseURL: 'https://lum-network-default-rtdb.firebaseio.com',
    projectId: 'lum-network',
    storageBucket: 'lum-network.appspot.com',
    messagingSenderId: '904911254564',
    appId: '1:904911254564:web:9c98be32e8e14b0f0394b1',
    measurementId: 'G-9VKR2JS53K',
};
