import { LUM_DISCORD, LUM_GITHUB, LUM_MEDIUM, LUM_TELEGRAM, LUM_TWITTER } from 'constant';

import github from 'assets/images/github.svg';
import twitter from 'assets/images/twitter.svg';
import discord from 'assets/images/discord.svg';
import medium from 'assets/images/medium.svg';
import telegram from 'assets/images/telegram.svg';

import './Footer.scss';

const Footer = () => {
    return (
        <footer className="mt-auto">
            <div className="d-flex justify-content-center justify-content-sm-start mb-4">
                <a href={LUM_GITHUB} rel="noreferrer" target="_blank" className="ms-0 ms-sm-4">
                    <img src={github} alt="Github" className="footer-logo" />
                </a>
                <a href={LUM_TWITTER} rel="noreferrer" target="_blank" className="ms-3">
                    <img src={twitter} alt="Twitter" className="footer-logo" />
                </a>
                <a href={LUM_DISCORD} rel="noreferrer" target="_blank" className="ms-3">
                    <img src={discord} alt="Discord" className="footer-logo" />
                </a>
                <a href={LUM_MEDIUM} rel="noreferrer" target="_blank" className="ms-3">
                    <img src={medium} alt="Medium" className="footer-logo" />
                </a>
                <a href={LUM_TELEGRAM} rel="noreferrer" target="_blank" className="ms-3">
                    <img src={telegram} alt="Telegram" className="footer-logo" />
                </a>
            </div>
        </footer>
    );
};

export default Footer;
