import React from 'react';

import './Button.scss';
import { Loading } from '../index';

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    outline?: boolean;
    inverted?: boolean;
    loading?: boolean;
}

const Button = (props: Props): JSX.Element => {
    const { children, className, outline, inverted, loading, ...rest } = props;
    return (
        <button
            {...rest}
            disabled={loading}
            className={`normal-btn ${outline && 'btn-outlined'} ${inverted && 'inverted'} ${className}`}
        >
            {loading ? <Loading /> : children}
        </button>
    );
};

export default Button;
